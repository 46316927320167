<template>
  <div class="page-class page-setting-list min-height-522">
    <div class="page-title text-center">
      <h2>{{ title }}</h2>
      <h6 class="">{{ subTittle }}</h6>
    </div>
    <div class="category">
      <a
        :class="{
          singleCategory: !isDarkMode,
          'singleCategory-dark dark-div box-shadow2': isDarkMode
        }"
        @click="budgetListA()"
      >
        <div class="categoryImage">
          <img src="../../assets/images/budget.png" rounded />
        </div>
        <div
          :class="{
            card_title: !isDarkMode,
            'card_title-dark text-light': isDarkMode
          }"
          >Budget</div
        ></a
      >
      <a
        :class="{
          singleCategory: !isDarkMode,
          'singleCategory-dark dark-div box-shadow2': isDarkMode
        }"
        @click="cashInHandA()"
      >
        <div class="categoryImage">
          <img src="../../assets/images/cash_in_hand.png" rounded class="card_image" />
        </div>
        <div
          :class="{
            card_title: !isDarkMode,
            'card_title-dark text-light': isDarkMode
          }"
          >Cash
        </div></a
      >
      <a
        :class="{
          singleCategory: !isDarkMode,
          'singleCategory-dark dark-div box-shadow2': isDarkMode
        }"
        @click="expenseListA()"
      >
        <div class="categoryImage">
          <img src="../../assets/images/expense.png" rounded class="card_image" />
        </div>
        <div
          :class="{
            card_title: !isDarkMode,
            'card_title-dark text-light': isDarkMode
          }"
          >Expense</div
        ></a
      >
      <b-modal
        id="modal-lg"
        :cancel-disabled="true"
        size="lg"
        :no-close-on-backdrop="true"
        v-model="modalVisible"
        title="Please verify the account"
        :header-bg-variant="isDarkMode ? 'dark' : 'light'"
        :header-text-variant="isDarkMode ? 'light' : 'dark'"
        :body-bg-variant="isDarkMode ? 'dark' : 'light'"
        :body-text-variant="isDarkMode ? 'light' : 'dark'"
        :footer-bg-variant="isDarkMode ? 'dark' : 'light'"
      >
        <div>
          <b-form-group id="input-otp" label-for="input-otp">
            <div class="my-1">
              <template class="">
                Enter OTP
                <span class="text-danger">*</span>
              </template>
            </div>
            <b-form-input
              :class="{ 'mb-2': !isDarkMode, 'mb-2 bg-dark text-light placeholder-light': isDarkMode }"
              id="input-otp"
              type="text"
              :state="$v.otp.$dirty ? !$v.otp.$error : null"
              v-model="otp"
              placeholder="Enter otp"
            ></b-form-input>

            <b-form-invalid-feedback id="input-otp-invalid">Please Enter the OTP</b-form-invalid-feedback>
          </b-form-group>
        </div>
        <div v-if="this.status === 'success'"> {{ notificationText }} </div>
        <template #modal-footer>
          <div v-if="loading" class="">
            <div class="text-center">
              <span class="spinner spinner-black"></span>
            </div>
          </div>
          <button
            @click="handleGenerateOtp"
            :disabled="isButtonDisabled"
            :class="{ 'btn btn-info': !isDarkMode, 'btn text-light btn-outline-info': isDarkMode }"
            >{{ buttonText }}</button
          >
          <button
            :disabled="isDisabled"
            @click="handleVerify"
            :class="{ 'btn btn-success': !isDarkMode, 'btn text-light btn-outline-success': isDarkMode }"
            >Verify OTP</button
          >
          <button
            @click="modalVisible = false"
            :class="{ 'btn btn-danger': !isDarkMode, 'btn text-light btn-outline-danger': isDarkMode }"
            >Cancel</button
          >
        </template>
      </b-modal>
    </div>
  </div>
</template>

<script>
import router from '@/router';
import Vue from 'vue';
import { required } from 'vuelidate/lib/validators';
import { mapState, mapActions, mapGetters } from 'vuex';

export default {
  name: 'PettyCash',
  mounted() {
    if (this.isChecked()) {
      this.modalVisible = true;
    }
    this.$nextTick(async () => {
      // this.form.status = Checklist.checklistEnabled.active;
      this.$v.$touch(); // Set initial validation
      this.$v.$reset(); // Reset $dirty
    });
  },
  validations() {
    const otp = { required };
    return { otp };
  },
  beforeDestroy() {
    clearInterval(this.countdownTimer);
  },
  methods: {
    ...mapActions('pettyCash', ['generateOtp', 'verifyOtp']),
    handleGenerateOtp() {
      if (!this.isButtonDisabled) {
        this.generateOtp();
        this.status = '';
        this.isButtonDisabled = true;

        this.countdownTimer = setInterval(() => {
          if (this.countdownSeconds > 0) {
            this.countdownSeconds--;
          } else {
            clearInterval(this.countdownTimer);
            this.isButtonDisabled = false;
            this.countdownSeconds = 120;
          }
        }, 1000);
      }
    },
    decrypt(encryptedText, key) {
      let decryptedText = '';
      for (let i = 0; i < encryptedText?.length; i++) {
        const charCode = encryptedText?.charCodeAt(i) ^ key.charCodeAt(i % key.length);
        decryptedText += String.fromCharCode(charCode);
      }
      return decryptedText;
    },
    isChecked() {
      if (this.user.company === 'NBL') {
        return false;
      }
      if (this.ifAccountant || this.ifSM || this.ifRSM || this.ifASM) {
        const decrypted = this.decrypt(sessionStorage.getItem('isAccountVerified'), 'secret key 123');
        if (decrypted !== 'Verified') {
          return true;
        }
      }
      return false;
    },
    handleVerify() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      this.verifyOtp({ otp: Number(this.otp) });
      this.modalVisible = false;
      this.otp = '';
      this.btnText = 'Generate OTP again';
      this.notificationText = '';
    },
    budgetListA() {
      if (this.isChecked()) {
        this.modalVisible = true;
      } else {
        router.push('/petty-cash/budget');
      }
    },
    expenseListA() {
      if (this.isChecked()) {
        this.modalVisible = true;
      } else {
        router.push('/petty-cash/expense');
      }
    },
    cashInHandA() {
      if (this.isChecked()) {
        this.modalVisible = true;
      } else {
        router.push('/petty-cash/account');
      }
    }
  },
  computed: {
    ...mapState('darkMode', ['isDarkMode']),
    ...mapState('auth', ['user']),
    ...mapState('pettyCash', ['loading', 'otpGenerated', 'isAccountVerified']),
    ...mapGetters('pettyCash', ['isSmAsmRsm', 'ifAdmin', 'ifAccountant', 'ifSM', 'ifRSM', 'ifASM']),
    buttonText() {
      return this.isButtonDisabled ? `Retry in ${this.countdownSeconds}s` : 'Generate OTP';
    }
  },

  data() {
    return {
      title: 'Petty Cash',
      subTittle: 'Distribute and manage cash to stores',
      modalVisible: false,
      otp: '',
      status: '',
      isDisabled: true,
      isButtonDisabled: false,
      countdownTimer: null,
      countdownSeconds: 60,
      btnText: 'Generate OTP',
      notificationText: 'OTP generated Successfully. Please check your email',
      accountVerified: sessionStorage.getItem('isAccountVerified')
    };
  },
  watch: {
    otpGenerated(newValue) {
      if (newValue !== '' && newValue === 'success') {
        this.status = newValue;
        this.isDisabled = false;
      }
    }
  }
};
</script>

<style>
.budgetList {
  width: 200px;
  height: 200px;
  border: 1px solid black;
  border-radius: 10px;
}
.dark-modal #modal-lg div {
  background: #464d54;
}
.category {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  position: relative;
  /* border: 1px solid black; */
}
.singleCategory {
  text-decoration: none;
  color: black;
  margin-inline: 12px;
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 17vw;
  min-width: 200px;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.singleCategory-dark {
  text-decoration: none;
  color: black;
  margin-inline: 12px;
  margin-top: 4%;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 17vw;
  min-width: 200px;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
}
.singleCategory:hover {
  cursor: pointer;
  transform: scale(1.03, 1.03);
  /* border: 1px solid #e7861b; */
  transition-duration: 500ms;
}
.singleCategory-dark:hover {
  cursor: pointer;
  transform: scale(1.03, 1.03);
  /* border: 1px solid #e7861b; */
  transition-duration: 500ms;
}
.categoryImage {
  /* background: rgba(231, 134, 27, 0.15); */
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
.card_image {
  /* width: 70%; */
  object-fit: contain;
  max-width: 100%;
  min-width: 60%;
  max-height: 90%;
  min-height: 60%;
}
.card_title {
  margin: 5px;
  padding-top: 3px;
  font-size: 20px;
  font-weight: 400;
  border-top: 1px solid #f28a3f;
  border-radius: 10px;
  width: 100%;
  text-align: center;
}
.card_title-dark {
  margin: 5px;
  padding-top: 3px;
  font-size: 20px;
  font-weight: 400;
  border-top: 1px solid white;
  border-radius: 10px;
  width: 100%;
  text-align: center;
}
@media screen and (max-width: 415px) {
  .singleCategory {
    width: 140px;
    min-width: 70px;
    /* height: 200px; */
  }
  .categoryImage {
    height: 18vh;
    /* border: 1px solid black; */
  }
}
</style>
